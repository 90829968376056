import React from 'react';

import About from './About/About';
import MastHead from './MastHead/MastHead';

const HomePage = () => {
  return (
    <>
      <MastHead />
      <About />
    </>
  )
}

export default HomePage;